<template>
  <div style="padding: 20px;">
    <div class="title">
      <div class="title_t">
        <span>{{ $route.meta.title }}</span>
      </div>
    </div>

    <div style="width: 1100px;background: #ffffff;border-radius: 10px; margin: auto;padding: 20px;">
      <div style="font-size: 30px;font-weight: 500;color: #000000;text-align: center;margin-top: 30px;">提现申请</div>
      <div style="text-align: center;margin-top: 30px;">
        <span style="font-size: 16px;font-weight: 500;color: #4e4e4e;">您要开通的服务是：</span>
        <span style="font-size: 18px;font-weight: 500;color: #202033;">白金年卡会员</span>
      </div>
      <div style="margin-top: 30px;text-align: center;">
        <span style="font-size: 16px;font-weight: 500; margin-right: 15px;">开通数量</span>
        <el-input-number v-model="num" @change="handleChange" :min="1" :max="10" label="描述文字"></el-input-number>
      </div>
      <div style="margin-top: 30px;text-align: center;">
        <span style="font-size: 16px;font-weight: 500;color: #4e4e4e;">支付开通服务费：</span>
        <span style="font-size: 18px;font-weight: 700;color: #ff9b05;">200元</span>
      </div>
      <div style="font-size: 14px;font-weight: 400;color: #7e7f8c;margin-top: 60px;">
        注:对于虚拟号码服务,每个订单自动对应几个虚拟号码(客服电话、司机电话、)，用户打入打出都是使用此号码,用户可以用虚拟号拨号，包括司机打给客户客户打给司机客户打给平台平台打给司机服务商购买时可修改虚拟号码的数量条数,条数价格在系统设置中定义。
      </div>
    </div>

    <div style="text-align: center; margin-top: 60px;">
      <el-button style="background: #FEA837 !important;border-color: #FEA837!important;color: #FFFFFF!important;">同意，去支付</el-button>
      <el-button @click="quxiao">取消</el-button>
    </div>

  </div>
</template>

<script>
  export default {
    data(){
      return{
        num:1
      }
    },

    methods:{
      handleChange(value) {
        console.log(value);
      },

      quxiao(){
        this.$router.go(-1);
      },
    }

  }
</script>

<style lang="scss" scoped>

</style>
